<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'requests'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="my-0" align="center">
        <v-col class="py-0">
          <v-tabs v-model="tab" background-color="transparent" class="grey--text text--lighten-1">
            <v-tab :ripple="false" href="#new" :disabled="loading" class="text-none">
              {{ 'new'|localize }}
            </v-tab>
            <v-tab :ripple="false" href="#accepted_booked" :disabled="loading" class="text-none">
              {{ 'booked'|localize }}
            </v-tab>
            <v-tab :ripple="false" href="#all" :disabled="loading" class="text-none">
              {{ 'allRequests'|localize }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="auto" class="py-0">
          <v-btn text color="primary" @click="resetFilters();clearRequestList();getRequests()" class="mr-2"><v-icon left>mdi-sync</v-icon> {{ 'resetFilters'|localize }}</v-btn>
        </v-col>
      </v-row>
      <v-divider class="mb-1"/>
      <v-row class="ma-0 mb-1">
        <v-col cols="4">
          <v-text-field
            :background-color="search ? 'serenade' : ''"
            v-model.trim="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            hide-details
            dense
            clearable
            :placeholder="'search'|localize"
          />
        </v-col>
      </v-row>
      <v-container class="py-0">
        <v-row justify="end" align="center">
          <v-col :cols="tab === 'all' ? '2' : '3'" class="pr-1">
            <v-select
              :background-color="filters.special_requirements.length ? 'serenade' : ''"
              v-model="filters.special_requirements"
              :items="requirementsList"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'specialRequirements'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" class="px-1">
            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="false"
              :return-value.sync="filters.date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :background-color="filters.date.length ? 'serenade' : ''"
                  :placeholder="'dateCreated' | localize"
                  :value="dateRangeText"
                  readonly
                  dense
                  hide-details
                  outlined
                  clearable
                  v-on="on"
                  @click:clear="clearRequestList();filters.date = [];getRequests()"
                />
              </template>
              <v-date-picker
                v-model="filters.date"
                no-title
                scrollable
                range
                @input="filters.date.length === 2 && $refs.menuDate.save(filters.date)"
                @change="clearRequestList();getRequests();"
              />
            </v-menu>
          </v-col>
          <v-col cols="3" class="px-1">
            <v-select
              :background-color="filters.request_type && 'serenade'"
              v-model="filters.request_type"
              :items="requestTypesList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              :placeholder="'requestType'|localize"
              @change="clearRequestList();getRequests();"
            />
          </v-col>
          <v-col :cols="tab === 'all' ? '2' : '3'" :class="tab === 'all' ? 'px-1' : 'pl-1'">
            <v-select
              :background-color="filters.transport_mode.length ? 'serenade' : ''"
              v-model="filters.transport_mode"
              :items="transportModeList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'transportMode'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon v-if="index <= 2" class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <span v-if="index === 3" class="grey--text text-caption">+{{ filters.transport_mode.length - 3 }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="tab === 'all'" cols="2" class="pl-1">
            <v-select
              :background-color="filters.status.length ? 'serenade' : ''"
              v-model="filters.status"
              :items="statusesList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'status'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item }">
                <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                <v-list-item-title colo>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon v-if="index <= 2" :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                <span v-if="index === 3" class="grey--text text-caption">+{{ filters.status.length - 3 }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="requestList"
        :loading="loading"
        item-key="id + transport_mode"
        loader-height="2"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc"
        :options.sync="options"
        :server-items-length="totalItems"
        hide-default-footer
        :no-data-text="'noDataAvailable'|localize"
      >
        <template v-slot:item.title="{ item }">
          <div class="py-2">
            <div>
              <v-tooltip v-for="type in item['special_requirements']" :key="type" top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-1" v-bind="attrs" v-on="on" :color="SPECIAL_REQUIREMENTS_COLORS[type]">{{ SPECIAL_REQUIREMENTS_ICONS[type] }}</v-icon>
                </template>
                <span>{{ type|localize }}</span>
              </v-tooltip>
              <router-link class="black--text mr-1" style="vertical-align: middle" :to="{name: 'admin-request-details', query: {request_id: item.id}}">
                {{ item.title }}
              </router-link>
            </div>
            <div v-if="item['client']">
              <i class="name-col text-caption">{{ 'client'|localize }}: {{ item['client'] }}</i>
            </div>
            <div v-if="item['customer_ref']">
              <i class="name-col text-caption">{{ 'customerRef'|localize }}: {{ item['customer_ref'] }}</i>
            </div>
          </div>
        </template>
        <template v-slot:item.weight="{ item }">
          <span>{{ item.weight }} kg</span>
        </template>
        <template v-slot:item.request_type="{ item }">
          <span>{{ item.request_type|localize }}</span>
        </template>
        <template v-slot:item.transport_modes="{ item }">
          <v-tooltip v-for="mode in item['transport_modes']" :key="mode" top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="item['booked_transport_mode'] === mode ? 'primary' : 'silver-chalice'">{{ TRANSPORT_ICONS[mode] }}</v-icon>
            </template>
            <span>{{ mode|localize }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.relevant_tc_qty="{ item }">
          <div class="d-flex align-center">
            {{ item['proposals_count'] }}/{{ item['relevant_tc_qty'] }}
            <v-tooltip v-if="item['company'].length > 0" left color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"  size="20" color='silver-chalice' class="ml-2">mdi-information-outline</v-icon>
              </template>
              <template>
                <div class="py-1">
                  <h5 class="text-subtitle-2 mb-1">{{ 'companies'|localize }}</h5>
                  <ul class="px-2">
                    <li v-for="company in item['company']" :key="company.id">{{ company.name }}</li>
                  </ul>
                </div>
              </template>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <RequestStatusCell :requestData="item" @updateRequestStatus="item.status = $event" :key="item.id" />
        </template>
      </v-data-table>
      <v-pagination
        color="primary"
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        class="py-6"
        :disabled="loading"
        @input="requestList = [];getRequests();"
      />
    </div>
  </v-container>
</template>

<script>
  import {TRANSPORT_ICONS, TRANSPORT_TYPES} from '@/enums/transportMode';
  import {STATUSES, STATUS_COLORS, STATUS_ICONS} from '@/enums/statuses';
  import { SPECIAL_REQUIREMENTS_COLORS, SPECIAL_REQUIREMENTS_ICONS } from '../enums/specialRequirements';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import RequestStatusCell from '@/components/RequestStatusCell.vue';

  export default {
    name: "AdminRequests",
    components: { RequestStatusCell },

    data() {
      return {
        TRANSPORT_TYPES,
        TRANSPORT_ICONS,
        STATUSES,
        STATUS_ICONS,
        STATUS_COLORS,
        tab: 'new',
        loading: false,
        debouncedSearch: '',
        debounceTimeout: null,
        defaultSortBy: 'date_created',
        defaultSortDesc: true,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        requestList: [],
        menuDate: false,
        filters: {
          status: [],
          transport_mode: [],
          request_type: null,
          date: [],
          special_requirements: []
        }
      };
    },

    watch: {
      tab() {
        this.filters.status = [];
        this.clearRequestList();
        this.getRequests();
      },
      options: {
        handler() {
          this.clearRequestList();
          this.getRequests();
        },
        deep: true,
      },
      search() {
        this.clearRequestList();
        this.getRequests();
      },
    },

    computed: {
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      transportModeList() {
        return this.$store.getters.transportModeList;
      },
      requirementsList() {
        return this.$store.getters.requirementsList;
      },
      requestTypesList() {
        return this.$store.getters.requestTypesList;
      },
      headers() {
        let localHeaders = [
          {text: 'ID', value: 'id', width: 80},
          {text: localize('dateCreated'), value: 'date_created', width: 'auto'},
          {text: localize('title'), value: 'title', width: 300},
          {text: localize('requestType'), value: 'request_type', width: 'auto'},
          {text: localize('transportMode'), value: 'transport_modes', width: 'auto'},
          {text: localize('quotes_companies'), value: 'relevant_tc_qty', width: 140},
          {text: localize('status'), value: 'status', width: 140},
        ];
        if (this.tab === 'new' || this.tab === 'accepted_booked') {
          localHeaders = localHeaders.filter((item) => item.value !== 'status');
        }
        return localHeaders;
      },
      statusesList() {
        let statuses = this.$store.getters.statuses;
        const validStatuses = [
          STATUSES.NEW,
          STATUSES.NEED_RESPONSE,
          STATUSES.CLOSED,
          STATUSES.DELETED,
          STATUSES.DRAFT,
          STATUSES.BOOKED,
          STATUSES.NOT_BOOKED,
          STATUSES.REJECTED,
          STATUSES.CANCELLED
        ];
        return statuses.filter(status => validStatuses.includes(status.value));
      },
      dateRangeText () {
        return this.filters.date.sort().join(' ~ ')
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      resetFilters() {
        this.filters.status = [];
        this.filters.transport_mode = [];
        this.filters.request_type = null;
        this.filters.date = [];
        this.filters.special_requirements = [];
      },
      clearRequestList() {
        this.requestList = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      setRequestsData(requestsData) {
        this.requestList = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      queryData() {
        let queryData = {};
        queryData['tab'] = this.tab;
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        this.search && (queryData['search'] = this.search);
        queryData.filters = {};
        this.filters.status.length && (queryData.filters.status = this.filters.status);
        this.filters.transport_mode.length && (queryData.filters.transport_mode = this.filters.transport_mode);
        this.filters.request_type && (queryData.filters.request_type = [this.filters.request_type]);
        this.filters.date.length && (queryData.filters.date = this.filters.date.sort());
        this.filters.special_requirements.length && (queryData.filters.special_requirements = this.filters.special_requirements);
        return queryData;
      },
      async getRequests() {
        try {
          this.loading = true;
          let requestsData = await api.getAdminRequests(this.queryData());
          this.setRequestsData(requestsData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }
    },
  };
</script>
