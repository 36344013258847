<template>
  <div style="position: relative">
    <v-select
      v-if="isStatusUpdate"
      v-model="localStatus"
      :items="availableStatuses"
      item-text="name"
      item-value="value"
      dense
      hide-details
      outlined
      :placeholder="'status'|localize"
      :loading="loading"
      :disabled="loading"
    >
      <template v-slot:item="{ item }">
        <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
        <v-list-item-title colo>{{ item.value|localize }}</v-list-item-title>
      </template>
      <template v-slot:selection="{ item, index }">
        <div class="d-flex text-no-wrap" style="min-width: 140px">
          <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
          <span class="grey--text">{{ item.value|localize }}</span>
        </div>
      </template>
    </v-select>
    <div v-else class="d-flex align-center">
      <v-icon :color="STATUS_COLORS[requestData.status]" size="20" class="mr-2">{{ STATUS_ICONS[requestData.status] }}</v-icon>
      <span>{{ requestData.status|localize }}</span>
    </div>
    <v-fade-transition>
      <div v-show="confirm" style="position: absolute; top: 3px;left: -90px; display: none;">
        <v-btn fab depressed x-small color="primary" class="mr-1" @click="updateStatus">
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn fab depressed x-small color="disabled" @click="localStatus = requestData.status">
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
  import { STATUSES, STATUS_COLORS, STATUS_ICONS } from '@/enums/statuses';
  import api from '@/api/api';
  import methods from '@/helpers/methods';

  export default {
    name: 'RequestStatusCell',

    props: ['requestData'],

    data() {
      return {
        STATUSES,
        STATUS_COLORS,
        STATUS_ICONS,
        confirm: false,
        loading: false,
        localStatus: '',
      };
    },

    mounted() {
      this.localStatus = this.requestData.status;
    },

    watch: {
      localStatus() {
        this.confirm = this.localStatus !== this.requestData.status;
      },
    },

    computed: {
      isStatusUpdate() {
        return this.requestData.status === STATUSES.NEW ||
          this.requestData.status === STATUSES.CLOSED ||
          this.requestData.status === STATUSES.NOT_BOOKED;
      },
      availableStatuses() {
        let statuses = this.$store.getters.statuses;
        return statuses.reduce((array, status) => {
          if (this.requestData.status === STATUSES.NEW) {
            if (
              status.value === STATUSES.NEW ||
              status.value === STATUSES.BOOKED ||
              status.value === STATUSES.CLOSED
            ) {
              array.push(status);
            }
          }
          if (this.requestData.status === STATUSES.CLOSED) {
            if (
              status.value === STATUSES.CLOSED ||
              status.value === STATUSES.BOOKED
            ) {
              array.push(status);
            }
          }
          if (this.requestData.status === STATUSES.NOT_BOOKED) {
            if (
              status.value === STATUSES.NOT_BOOKED ||
              status.value === STATUSES.BOOKED
            ) {
              array.push(status);
            }
          }
          return array;
        }, []);
      },
    },

    methods: {
      queryData() {
        let queryData = {};
        queryData.request_id = this.requestData.id;
        queryData.status = this.localStatus;
        return queryData;
      },
      async updateStatus() {
        this.confirm = false;
        try {
          this.loading = true;
          await api.updateRequestStatus(this.queryData());
          let newRequestData = await api.getSingleAdminRequest(this.requestData.id);
          this.$emit('updateRequestStatus', newRequestData.data.status);
          this.loading = false;
          this.localStatus = newRequestData.data.status;
        } catch (e) {
          this.loading = false;
          this.localStatus = this.requestData.status;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
    },
  };
</script>

